<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
const fontFamily = 'Libre Franklin';
</script>

<template>
    <div id="flavor-gfx">
        <Head>
            <Link
                rel="stylesheet"
                :href="`https://fonts.googleapis.com/css2?family=${encodeURIComponent(fontFamily)}:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap`"
            />
        </Head>
        <slot></slot>
    </div>
</template>

<style lang="scss">
@import "@/assets/flavors/gfx/style";

.flavor-gfx {
    /* stylelint-disable-next-line value-keyword-case */
    --flavor-font-family: v-bind(fontFamily);
}
</style>
